import React from 'react'
import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import Portfolio from '../components/portfolio/Portfolio'
import useDocumentTitle from '../components/useDocumentTitle';
 

const videoList = [
    {
        'title' :'Staff Meet Speech 2021',
        'link' :'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4'
    },
    {
        'title' :'Lighting Seminar Speech 2019',
        'link' :'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4'
    }
];
export default function VideoGallery() {
  useDocumentTitle('Video Gallery')
  return (
    <div className="home-dark">
    <Header />
    <section id="photo-gallery" className="section gray-bg">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
            <h3 className="font-alt">Videos</h3>
              {/* <h3 className="font-alt">Ascon Inauguration Speech 2022
</h3> */}
              {/* <p>
                I design and develop services for customers of all sizes,
                specializing in creating stylish, modern websites, web services
                and online stores.
              </p> */}
            </div>
          </div>
          {/* End .row */}
          <Portfolio  />
        </div>
      </section>
    <footer className="footer white">
          <div className="container">
            <Footer />
          </div>
        </footer>
    </div>
  )
}
