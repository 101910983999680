import React from "react";
import { videoTagString, VideoTag } from "react-video-tag";
const About = () => {
  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-6 my-new">
          <div className="img-box dark-img-box">
            {/* <img src="img/about/welcome.jpg" alt="smiling a girl" /> */}
            <VideoTag
            autoPlay={`${true}`}
            muted={false}
            width="200"
            height="200"
            playsInline={`${true}`}
            loop={`${true}`}
            src={`${"https://pambavasannair.com/videos/pambavasan-nair-profile-2022.mp4"}`}
              poster={`${"img/about/welcome.jpg"}`}
          />
          </div>
        </div>

        <div className="col-lg-6 my-3">
          <div className="typo-box about-me">
            <h3>Pambavasan Nair</h3>
            {/* <h5>
              A Lead <span className="color-theme">UX &amp; UI</span> designer
              based in <span className="color-theme">Canada</span>
            </h5> */}
            <p style={{fontStyle:"italic",fontSize:'20px',color:'orange'}}>
            “Successful people are not gifted; they just work hard, then succeed on purpose”  
            </p>
             <p>
             He started his career as a trainee maintenance engineer and because of his excellent performance got soon promoted as Shift Engineer. Being quite ambitious he switched on to sales and joined a switchgear manufacturing company. The management realizing his potentials, posted him as Sr. Sales Engineer and soon he was promoted as Sales Manager. In the year 1993, he reached Saudi Arabia as Sales Engineer wherein he was entrusted the position as Project & Showroom Sales Manager. In the year 1998 he ventured into business, starting a trading company called Amad Al Baeed Trading Est. in Saudi Arabia. The company within a short span of 2-3 years got well established. Following the success in Saudi Arabia, his business expansion in Bahrain was initiated in the year 2001. Amad Group has today successfully completed 26 years of an incredible journey in the gulf region of Bahrain and Saudi Arabia. Amad presently has 4 companies in Bahrain and 3 companies in Saudi Arabia with 450 employees. With his exemplary entrepreneurial skills, he nurtured Amad into a professional business enterprise.
             </p>

            

           
            {/* End .row */}
           
          </div>
          
          {/* End .about me */}
        </div>
       
        {/* End .col */}
      </div>
      
    </>
  );
};

export default About;
