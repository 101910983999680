import React from "react";
import {Helmet} from "react-helmet";
const NewAbout = () => {
  return (
    <>
         
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-6 my-3">
          <div className="img-box dark-img-box">
            <img src="img/about/Pambavasan.jpg" alt="smiling a girl" />
          </div>
        </div>

        <div className="col-lg-6 my-3">
          <div className="typo-box about-me">
            <h1>Pambavasan Nair</h1>
            {/* <h5>
              A Lead <span className="color-theme">UX &amp; UI</span> designer
              based in <span className="color-theme">Canada</span>
            </h5> */}
            <p style={{fontStyle:"italic",fontSize:'20px',color:'orange'}}>
            “Successful people are not gifted; they just work hard, then succeed on purpose”  
            </p>
             <p>
             Hailing from a small village in Palakkad district called Trikkaderi, Mr Pambavasan Nair belongs to an agrarian family. 
             His father Late Sri Madhavan Nair was a farmer, quite well known in his hometown. Having been a studious & diligent 
             student throughout his academics, he excelled as the school topper. On completing his Diploma in Electrical Engineering
              from Perinthalmanna Polytechnic Government College, he further pursued Graduation in BA & Diploma in Marketing 
              Management from Mumbai University, Maharashtra.
             <a href="about-us">  Read More...</a>
             </p>

           
            {/* End .row */}
           
          </div>
          
          {/* End .about me */}
        </div>
       
        {/* End .col */}
      </div>
      
    </>
  );
};

export default NewAbout;
