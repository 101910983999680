import React from "react";
import Preview from "../views/Preview";
import HomeLight from "../views/all-home-version/HomeLight";
import HomeLightAnimation from "../views/all-home-version/HomeLightAnimation";
import HomeDarkAnimation from "../views/all-home-version/HomeDarkAnimation";
import HomeDark from "../views/all-home-version/HomeDark";
import HomeVideoLight from "../views/all-home-version/HomeVideoLight";
import HomeVideoDark from "../views/all-home-version/HomeVideoDark";
import NotFound from "../views/NotFound";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";
import Abouts from "../Pages/Abouts";  
import GroupCompany from "../Pages/GroupCompany";
import Services from "../Pages/Services";
import PhotoGallery from "../Pages/PhotoGallery";
import VideoGallery from "../Pages/VideoGallery";
import Contact from "../Pages/Contacts";
import Contacts from "../Pages/Contacts";
import Article from "../Pages/Article";
import Award from "../Pages/Award";
import News from "../Pages/News";
import CMNTrust from "../Pages/CMNTrust";
const Routes = () => {
  return (
    <>
      <Router>
        {/* <ScrollTopBehaviour /> */}
        <Switch>
          <Route exact path="/" component={HomeVideoDark} />
          <Route path="/about-us" component={Abouts} />
          <Route path="/group-companies" component={GroupCompany} />
          <Route path="/services" component={Services} />
          <Route path="/awards" component={Award} />
          <Route path="/photo-gallery" component={PhotoGallery} />
          <Route path="/video-gallery" component={VideoGallery} />
          <Route path="/articles" component={Article} />
          <Route path="/contact-us" component={Contacts} />
          <Route path="/News" component={News} />
          <Route path="/CMNTrust" component={CMNTrust} />
          
          {/* <Route path="/home-light" component={HomeLight} />
          <Route path="/home-light-animation" component={HomeLightAnimation} />
          <Route path="/home-dark-animation" component={HomeDarkAnimation} />
          <Route path="/home-dark" component={HomeDark} />
          <Route path="/home-video-light" component={HomeVideoLight} />
          <Route path="/home-video-dark" component={HomeVideoDark} /> */}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
