import React,{ useState,useEffect,useRef } from "react";
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
const ImagePortfolio = () => {
const tabList = ["All", "Branding", "Photography", "Fashion", "Product"];
const [allPortfolioContent,setAllPortfolioContent]	=useState([]);
useEffect(() => {   
	const fetchdata = async () => {
	 try {

	   const response = await axios.post('https://pambavasannair.com/admin/api/images');	 
	   
		const galaryImages = response.data.data.newsevents;
   // alert(JSON.stringify(galaryImages));
    setAllPortfolioContent(galaryImages);
	
			
		} catch (error) {
			if (axios.isAxiosError(error)) {
				console.error('Axios Error:', error.response.data);
			  } else {
				console.error('Non-Axios Error:', error);
			  }
			}
	   }
	   fetchdata();   
},[]) ;
/*
const BrandingPortfolioContent = [
  {
    img: "1",
    title: "Web Application",
    subTitle: "Behance Shot",
    alterText: "Web Application",
    portfolioLink:
      "https://dribbble.com/shots/16529226-Krozo-Personal-Portfolio-React-Template",
  },

  {
    img: "7",
    title: "Code Editing",
    subTitle: "Codecanyon Market",
    alterText: "Bottle Illustration",
    portfolioLink:
      "https://dribbble.com/shots/16529158-Waxon-Personal-Portfolio-VueJS-Template-RTL",
  },
  {
    img: "3",
    title: "Visual Design",
    subTitle: "Themeforest Marke",
    alterText: "Business Mockup",
    portfolioLink:
      "https://dribbble.com/shots/16529407-Deski-Saas-Software-React-Template",
  },
];

const PhotographyPortfolioContent = [
  {
    img: "4",
    title: "Code Editing",
    subTitle: "Codecanyon Market",
    alterText: "Bottle Illustration",
    portfolioLink:
      "https://dribbble.com/shots/16529158-Waxon-Personal-Portfolio-VueJS-Template-RTL",
  },
  {
    img: "6",
    title: "Web Application",
    subTitle: "Behance Shot",
    alterText: "Web Application",
    portfolioLink:
      "https://dribbble.com/shots/16529226-Krozo-Personal-Portfolio-React-Template",
  },
];
const FashionPortfolioContent = [
  {
    img: "4",
    title: "Code Editing",
    subTitle: "Figma Shoot",
    alterText: "Bottle Illustration",
    portfolioLink:
      "https://dribbble.com/shots/16529339-Beny-Personal-Portfolio-React-Template",
  },
];

const ProductPortfolioContent = [
  {
    img: "3",
    title: "E-Learning App",
    subTitle: "Nuna ios App",
    alterText: "Illustration",
    portfolioLink:
      "https://dribbble.com/shots/16529350-Nairo-Personal-Portfolio-React-Template",
  },
  {
    img: "5",
    title: "Visual Design",
    subTitle: "Themeforest Marke",
    alterText: "Business Mockup",
    portfolioLink:
      "https://dribbble.com/shots/16529407-Deski-Saas-Software-React-Template",
  },

  {
    img: "6",
    title: "Chatting Application",
    subTitle: "Codecanyon Market",
    alterText: "Bottle Illustration",
    portfolioLink:
      "https://dribbble.com/shots/16529158-Waxon-Personal-Portfolio-VueJS-Template-RTL",
  },
];
*/

  return (
    <SimpleReactLightbox>
      <div className="positon-relative">
        <div className="portfolio-filter-01">
          <Tabs>
            {/* <TabList className="filter d-flex justify-content-center">
              {tabList.map((val, i) => (
                <Tab key={i}>{val}</Tab>
              ))}
            </TabList> */}
            {/* End tablist */}

            <SRLWrapper>
              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  {allPortfolioContent.map((val, i) => (
                    <div
                      className="col-sm-6 col-lg-4 grid-item product"
                      key={i}
                    >
                      <div className="portfolio-box-03">
                        <div className="portfolio-img">

                          <a
                            href={`admin/uploads/img/portfolio/gallery/${val.img}`}
                            className="gallery-link"
                          >
                            <img
                              src={`admin/uploads/img/portfolio/gallery/${val.img}`}
                              alt={val.alterText}
                            />
                          </a>
                          <div className="portfolio-info">
                            {/* <h5>
                              <a href={val.portfolioLink} target="_blank">
                                {val.title}
                              </a>
                            </h5> */}
                            <span>{val.alterText}</span>
                          </div>
                          {/* End gallery link */}
                          {/* <a
                            href={val.portfolioLink}
                            className="portfolio-icon"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="ti-link"></span>
                          </a> */}
                          {/* End .portfolio-icon */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* End list wrapper */}
              </TabPanel>

            </SRLWrapper>
            {/* End tabpanel */}
          </Tabs>
        </div>
      </div>
    </SimpleReactLightbox>
  );
};

export default ImagePortfolio;
