import React from "react";
import TextLoop from "react-text-loop";
import { FaAngleDown } from "react-icons/fa";
import Social from "../Social";
import { videoTagString, VideoTag } from "react-video-tag";
videoTagString({
  src: "https://pambavasannair.com/videos/intro.mp4",
  poster: "",
  type: 'video/mp4',
  muted: false,
  autoPlay: true,
  playsInline: true,
  loop: true
});

const Slider = () => {

  const defaults = {
    src: "https://pambavasannair.com/videos/intro.mp4",
    poster: "",
    type: 'video/mp4',
    muted: false,
    autoPlay: true,
    playsInline: true,
    loop: true
  }
  return (
    <>
      <section
        id="home"
        className="home-banner overlay overlay-0 inner-content-static video-banner video-banner-dark"
        // style={{
        //   backgroundImage: `url(${
        //     process.env.PUBLIC_URL + "img/banner/bg-2.jpg"
        //   })`,
        // }}
      >
        <div className="container">
          <div className="row align-items-center full-screen">
            <div className="col-lg-12">
              <div className="hb-typo text-center">
                
                {/* End social slide  */}
              </div>
            </div>
          </div>
        </div>
        {/* End .container */}

        <div className="video-background">
          <VideoTag
            autoPlay={`${true}`}
            muted={`${false}`}
            playsInline={`${true}`}
            loop={`${true}`}
            src={`${"https://pambavasannair.com/videos/intro.mp4"}`}
            poster={`${"img/bg-2.jpg"}`}
          />
        </div>

        <div className="go-to go-to-next">
          <a href="">
            <span></span>
          </a>
        </div>
        {/* End bottom scroll */}
      </section>
      {/* End Home Banner  */}
    </>
  );
};

export default Slider;
