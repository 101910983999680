import React from 'react'
import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import ArticleGallery from '../components/portfolio/ArticleGallery'
import useDocumentTitle from '../components/useDocumentTitle'
export default function Article() {
  useDocumentTitle('Articles')
  return (
    <div className="home-dark">
    <Header />
    <section id="articles" className="section gray-bg">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Articles</h3>
              {/* <p>
                I design and develop services for customers of all sizes,
                specializing in creating stylish, modern websites, web services
                and online stores.
              </p> */}
            </div>
          </div>
          {/* End .row */}
          <ArticleGallery />
        </div>
      </section>
    <footer className="footer white">
          <div className="container">
            <Footer />
          </div>
        </footer>
    </div>
  )
}
