import React from 'react'
import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import useDocumentTitle from '../components/useDocumentTitle'
export default function Services() {
  useDocumentTitle('Social Services')
  return (
    <div className="home-dark">
    <Header />
    <section id="services" className="section services-section gray-bg">
        <div className="container">
          <div className="row justify-content-left section-title text-center">
            <div className="col-lg-12">
              <h3 className="font-alt">Social & Charitable Services</h3>
              <p style={{fontStyle:"italic",fontSize:'20px',color:'orange'}}>
              “Service to mankind is service to God”
              </p>
              <div style={{textAlign:'justify',marginTop:'50px'}}>
              <img  src="img/service/1.jpeg" style={{width:'500px',float:'left', padding: '0 20px 20px 0'
}}/>
              <p>
              Mr Pambavasan Nair is a well known philanthropist. His commitment to society was evident during the pandemic crises. The landmark Sankar Hospital located in the Cherpulassery town - Kerala is a 100 bedded hospital owned by Mr Nair. He ventured into healthcare industry holding on to a strong intention of providing best health care facilities to the people in his hometown. During the pandemics, for two years the hospital was entrusted to the government for COVID patients quarantine & treatment. The hospital as a quarantine centre proved a great relief to the poor and less fortunate including native returning expatriates. The hospital was handed back after two years only, once covid restrictions were eased by the government.
               </p>





               
              </div>
            </div>
            <div className="row">
               


                    <div className='col-lg-6'>
              
              <p style={{textAlign:'justify'}} >
              His social & cultural commitment is well noted in his philanthropic works. He was unanimously elected twice as the President of Kerala Social & Cultural Association for the term 2013-2015 & again in the year 2017-2019. During his tenure, he got the opportunity to conduct "Kalamezhuthum Pattum", a traditional temple art form of Kerala performed by world-famous artist Sri Kallattu Manikandan, it was conducted  for the first time in the GCC. He was determined to promote ancient age-old art forms and to introduce it to the coming generations. Also, the prestigious “Mannam Awards” (Bharata Kesari Mannathu Padmanabha Pillai) was introduced to honour the deserving Indian personalities in their respective field every year. In his tenure he introduced Mannam Balakalolsavam 10-day event exclusively for the children, rendering a platform to showcase their talent. </p>
                            </div>

                            <div className='col-lg-6'>
                              <div className='row'>
                                    <div className='col-lg-6'>
                                        <img  src="img/service/Temple2.jpg" style={{width:'750px'}} />
                                    </div>
                                    <div className='col-lg-6'>
                                        <img  src="img/service/Temple1.jpg" style={{width:'750px'}} />
                                        </div> 
                                </div>
                           </div>
                           </div>


                            <div className='row'>
                          
                              <div className='col-lg-6'>

                                <p style={{textAlign:'justify'}}>
                                He is the founder & Chief Patron of People’s Forum Bahrain, an association formed in the service to mankind in the year 2008 to extend enormous financial aids to the destitute & doing charity work for humanitarian causes. His charity works are not limited to any caste, creed, or religion. But purely based on humanitarian grounds wherein he extends all possible help in his capacity. 
                                </p><p  style={{textAlign:'justify'}}>Mr Nair has been involved in notable & charitable works since many decades. He has been regularly donating to numerous temple construction & reconstruction works in India. He has contributed towards Ram Janmbhoomi Ayodhya, Siddhivinayak temple Mumbai, Thirupathi temple Andhrapradesh, shaneeshwara temple Ahmednagar, Anthimahakalan temple Kerala, to name a few. He renders financial support for medical treatments & provides monthly aids to many families. Also, he sponsors students for their education & has helped educational institutions by donating a considerable amount. He has built houses for the underprivileged & has given financial aid to many for their house construction. Also, he renders help to trusts with funds. He is also taking care of the families of his late employees who were the sole breadwinners by providing them monthly expenses. He also provides monthly aid for medical treatment to the ex- employees who are now settled in India. He has been making contribution towards Government Distress & Relief Funds time & again during crises like floods & pandemics.
                                </p><p  style={{textAlign:'justify'}}>In the year 2023, he formed a CMN trust in the name of his tate parents to channelize his charity works in a much more appropriate manner towards the benefit of the society.
                                </p></div>
                              <div className='col-lg-6'>

                                 
                               <div className='row'>
                               <div className='col-lg-6'>
                               <div className='col' style={{marginBottom:'5px'}}>
                               <img  src="img/service/5.jpeg" style={{width:'750px'}} />
                               </div>
                               <div className='col'>
                               <img  src="img/service/4.jpeg" style={{width:'750px',marginBottom:'5px'}} />
                               </div>
                              
                                
                               
</div>
<div className='col-lg-6'>
<img  src="img/service/3.jpeg" style={{width:'750px',marginBottom:'10px'}} />
  </div>
                               </div>


                                
                                

                                <img  src="img/service/2.jpeg" style={{width:'100%'}}/>
                            </div>
                            {/* <p style={{textAlign:'justify'}}>
                            He is the founder & Chief Patron of People’s Forum Bahrain, an association formed in the service to mankind in the year 2008 to extend enormous financial aids to the destitute & doing charity work for humanitarian causes.  His charity works are not limited to any caste, creed, or religion. But purely based on humanitarian grounds he extends all possible help in his capacity.<img  src="service/2.jpeg" style={{width:'250px',float:'left',padding:'20px'}} /> He renders financial support for medical treatments & provides monthly aids to many families. Also, he sponsors students for their education & has helped educational institutions by donating a considerable amount. He has built houses for the underprivileged & has given financial aid to many for their house construction. Also, he renders help to trusts with funds. <img  src="service/5.jpeg" style={{width:'250px',float:'right',padding:'20px'}} /> He is also taking care of the families of his employees who lost their life by providing monthly expenses & for those who are in India for medical treatment.             He has been making contribution towards Government Distress & Relief Funds time & again during crises like floods & pandemics. And he has always extended financial help for marriage thus helping the families in need of aid. 

                            </p> */}
                        
                            </div>
    
          </div>
          {/* End .row */}
          {/* <Service /> */}
        </div>
      </section>
      <br/><br/><br/>
    <footer className="footer white">
          <div className="container">
            <Footer />
          </div>
        </footer>
    </div>
  )
}
