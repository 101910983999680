import React,{ useState,useEffect,useRef } from "react";
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
const NewsPortfolio = () => {
const tabList = ["All", "Branding", "Photography", "Fashion", "Product"];
const [allPortfolioContent,setAllPortfolioContent]	=useState([]);
useEffect(() => {   
	const fetchdata = async () => { 
	 try {

	   const response = await axios.post('https://pambavasannair.com/admin/api/news');	 
	   
		const galaryImages = response.data.data.newsevents;
    //alert(JSON.stringify(galaryImages));
    setAllPortfolioContent(galaryImages);
	
			
		} catch (error) {
			if (axios.isAxiosError(error)) {
				console.error('Axios Error:', error.response.data);
			  } else {
				console.error('Non-Axios Error:', error);
			  }
			}
	   }
	   fetchdata();   
},[]) ;

  return (
    <SimpleReactLightbox>
      <div className="positon-relative">
        <div className="portfolio-filter-01">
          <Tabs>
            {/* <TabList className="filter d-flex justify-content-center">
              {tabList.map((val, i) => (
                <Tab key={i}>{val}</Tab>
              ))}
            </TabList> */}
            {/* End tablist */}

            <SRLWrapper>
              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  {allPortfolioContent.map((val, i) => (
                    <div
                      className="col-sm-6 col-lg-4 grid-item product"
                      key={i}
                    >
                      <div className="portfolio-box-03">
                        <div className="portfolio-img">
                          
                          {/* End .portfolio-info */}
                          <a
                            href={`admin/uploads/img/news/${val.img}`}
                            className="gallery-link"
                          >
                            <img
                              src={`admin/uploads/img/news/${val.img}`}
                              alt={val.alterText}
                            />
                          </a>
                          <div className="portfolio-info">
                         
                            <span>{val.alterText}</span>
                          </div>
                        
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
      
              </TabPanel>

              
            </SRLWrapper>

          </Tabs>
        </div>
      </div>
    </SimpleReactLightbox>
  );
};

export default NewsPortfolio;
