import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import VideoJS from "../../Pages/VideoJS";
//import { Player,BigPlayButton } from 'video-react';

const Portfolio = () => {

const tabList = ["All", "Professional Speeches", "Social Speeches"];
const [videoList, setVideoList] = useState([]);
useEffect(() => {   
	const fetchdata = async () => { 
	 try {

	   const response = await axios.post('https://pambavasannair.com/admin/api/videos');	 
	   
		const galaryImages = response.data.data.newsevents;
    //alert(JSON.stringify(galaryImages));
    setVideoList(galaryImages);
	
			
		} catch (error) {
			if (axios.isAxiosError(error)) {
				console.error('Axios Error:', error.response.data);
			  } else {
				console.error('Non-Axios Error:', error);
			  }
			}
	   }
	   fetchdata();   
},[]) ;

const BrandingPortfolioContent = [
  {

    autoplay: false,
    controls: true,
    responsive: true,
    title :'Lighting Seminar Speech 2019',
    fluid: true,
    sources: [{
      src: 'https://pambavasannair.com/vids/Lighting-Seminar-2019.mp4',
      type: 'video/mp4'
    }
    
  ]

  },
  {

    autoplay: false,
    controls: true,
    responsive: true,
    title :'Indo Asian Seminar Speech 2014',
    fluid: true,
    sources: [{
      src: 'https://pambavasannair.com/vids/Indo-Asian-Seminar-2014.mp4',
      type: 'video/mp4'
    }
    
  ]

  },
  {

    autoplay: false,
    controls: true,
    responsive: true,
    title :'Ascon Inaguration Speech 2022',
    fluid: true,
    sources: [{
      src: 'https://pambavasannair.com/vids/Ascon-Inaguration-Speech-2022.mp4',
      type: 'video/mp4'
    }
    
  ]

  }
];

const PhotographyPortfolioContent = [
  {

    autoplay: false,
    controls: true,
    responsive: true,
    title :'Hariharalayam Speech 2019',
    fluid: true,
    sources: [{
      src: 'https://pambavasannair.com/vids/Hariharalayam-2019.mp4',
      type: 'video/mp4'
    }
    
  ]

  }
  ,
  {

    autoplay: false,
    controls: true,
    responsive: true,
    title :'Saktheyam Speech 2018',
    fluid: true,
    sources: [{
      src: 'https://pambavasannair.com/vids/Saktheyam-2018.mp4',
      type: 'video/mp4'
    }
    
  ]

  }
  ,

  {

    autoplay: false,
    controls: true,
    responsive: true,
    title :'World Malayalee Council Speech 2022',
    fluid: true,
    sources: [{
      src: 'https://pambavasannair.com/vids/World-Malayalee-Council-Speech-2022-1-1-1.mp4',
      type: 'video/mp4'
    }
    
  ]

  },
  {

    autoplay: false,
    controls: true,
    responsive: true,
    title :'Mannam Fest Speech 2018',
    fluid: true,
    sources: [{
      src: 'https://pambavasannair.com/vids/Mannam-Fest-2018.mp4',
      type: 'video/mp4'
    }
    
  ]

  },
  {

    autoplay: false,
    controls: true,
    responsive: true,
    title :'Mandarapookkal Speech 2018',
    fluid: true,
    sources: [{
      src: 'https://pambavasannair.com/vids/Mandarapookkal-2018.mp4',
      type: 'video/mp4'
    }
    
  ]

  }
  ,
  {

    autoplay: false,
    controls: true,
    responsive: true,
    title :'Induction Ceremony Speech 2017',
    fluid: true,
    sources: [{
      src: 'https://pambavasannair.com/vids/induction-ceremony-2017.mp4',
      type: 'video/mp4'
    }
    
  ]

  }
  ,
  {

    autoplay: false,
    controls: true,
    responsive: true,
    title :'Thirumulkkazhcha Speech 2014',
    fluid: true,
    sources: [{
      src: 'https://pambavasannair.com/vids/Thirumulkkazhcha2014.mp4',
      type: 'video/mp4'
    }
    
  ]

  },
  
  {

    autoplay: false,
    controls: true,
    responsive: true,
    title :'Mannam Award Speech 2014',
    fluid: true,
    sources: [{
      src: 'https://pambavasannair.com/vids/Mannam-Award-Speech-2014.mp4',
      type: 'video/mp4'
    }
    
  ]

  },
  
];

/*
const videoList = [
 
  {

    autoplay: false,
    controls: true,
    responsive: true,
    title :'Lighting Seminar Speech 2019',
    fluid: true,
    sources: [{
      src: 'https://pambavasannair.com/vids/Lighting-Seminar-2019.mp4',
      type: 'video/mp4'
    }
    
  ]

  },
  {

    autoplay: false,
    controls: true,
    responsive: true,
    title :'Indo Asian Seminar Speech 2014',
    fluid: true,
    sources: [{
      src: 'https://pambavasannair.com/vids/Indo-Asian-Seminar-2014.mp4',
      type: 'video/mp4'
    }
    
  ]

  },

  {

    autoplay: false,
    controls: true,
    responsive: true,
    title :'World Malayalee Council Speech 2022',
    fluid: true,
    sources: [{
      src: 'https://pambavasannair.com/vids/World-Malayalee-Council-Speech-2022-1-1-1.mp4',
      type: 'video/mp4'
    }
    
  ]

  },
  {

    autoplay: false,
    controls: true,
    responsive: true,
    title :'Ascon Inaguration Speech 2022',
    fluid: true,
    sources: [{
      src: 'https://pambavasannair.com/vids/Ascon-Inaguration-Speech-2022.mp4',
      type: 'video/mp4'
    }
    
  ]

  } 
  ,
    {

      autoplay: false,
      controls: true,
      responsive: true,
      title :'Hariharalayam Speech 2019',
      fluid: true,
      sources: [{
        src: 'https://pambavasannair.com/vids/Hariharalayam-2019.mp4',
        type: 'video/mp4'
      }
      
    ]

    }
    ,
    {

      autoplay: false,
      controls: true,
      responsive: true,
      title :'Saktheyam Speech 2018',
      fluid: true,
      sources: [{
        src: 'https://pambavasannair.com/vids/Saktheyam-2018.mp4',
        type: 'video/mp4'
      }
      
    ]

    }
    ,

    
    {

      autoplay: false,
      controls: true,
      responsive: true,
      title :'Mannam Fest Speech 2018',
      fluid: true,
      sources: [{
        src: 'https://pambavasannair.com/vids/Mannam-Fest-2018.mp4',
        type: 'video/mp4'
      }
      
    ]

    },
    {

      autoplay: false,
      controls: true,
      responsive: true,
      title :'Mandarapookkal Speech 2018',
      fluid: true,
      sources: [{
        src: 'https://pambavasannair.com/vids/Mandarapookkal-2018.mp4',
        type: 'video/mp4'
      }
      
    ]

    }
    ,
    {

      autoplay: false,
      controls: true,
      responsive: true,
      title :'Induction Ceremony Speech 2017',
      fluid: true,
      sources: [{
        src: 'https://pambavasannair.com/vids/induction-ceremony-2017.mp4',
        type: 'video/mp4'
      }
      
    ]

    }
    ,
    {

      autoplay: false,
      controls: true,
      responsive: true,
      title :'Thirumulkkazhcha Speech 2014',
      fluid: true,
      sources: [{
        src: 'https://pambavasannair.com/vids/Thirumulkkazhcha2014.mp4',
        type: 'video/mp4'
      }
      
    ]

    },
    
    {

      autoplay: false,
      controls: true,
      responsive: true,
      title :'Mannam Award Speech 2014',
      fluid: true,
      sources: [{
        src: 'https://pambavasannair.com/vids/Mannam-Award-Speech-2014.mp4',
        type: 'video/mp4'
      } 
    ]

  },
];
*/
//const  {videoList} = props;
const playerRef = useRef(null);

const handlePlayerReady = (player) => {
  playerRef.current = player;

  // You can handle player events here, for example:
  player.on('waiting', () => {
    player.log('player is waiting');
  });

  player.on('dispose', () => {
    player.log('player will dispose');
  });
};

 // console.log('new data' + JSON.stringify(videoList))
  return (

  
    <SimpleReactLightbox>
      <div className="positon-relative">
        <div className="portfolio-filter-01">
          <Tabs>
            {/* <TabList className="filter d-flex justify-content-center">
              {tabList.map((val, i) => (
                <Tab key={i}>{val}</Tab>
              ))}
            </TabList> */}
            {/* End tablist */}

            <SRLWrapper>
              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  {videoList.map((val, i) => (
                    <div
                      className="col-sm-6 col-lg-6 grid-item product"
                      key={i}
                    >
                      <div className="portfolio-box-01">
                        <div className="portfolio-img">
                          <div className="portfolio-info">
                        <p>{val.portfolioLink}</p>
                            <h5>
                              <a href={val.portfolioLink} target="_blank">
                                {val.title}  
                              </a>
                            </h5>
                            {/* <span>{val.subTitle}</span> */}
                          </div>
                          {/* End .portfolio-info */}
                          <VideoJS options={videoList[i]} onReady={handlePlayerReady} />

                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* End list wrapper */}
              </TabPanel>

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                {BrandingPortfolioContent.map((val, i) => (
                    <div
                      className="col-sm-6 col-lg-6 grid-item product"
                      key={i}
                    >
                      <div className="portfolio-box-01">
                        <div className="portfolio-img">
                          <div className="portfolio-info">
                          <p>2</p>
                            <h5>
                              <a href={val.portfolioLink} target="_blank">
                                {val.title}
                              </a>
                            </h5>
                            {/* <span>{val.subTitle}</span> */}
                          </div>
                          {/* End .portfolio-info */}
                          <VideoJS options={BrandingPortfolioContent[i]} onReady={handlePlayerReady} />

                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* End list wrapper */}
              </TabPanel>

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                {PhotographyPortfolioContent.map((val, i) => (
                    <div
                      className="col-sm-6 col-lg-6 grid-item product"
                      key={i}
                    >
                      <div className="portfolio-box-01">
                        <div className="portfolio-img">
                          <div className="portfolio-info">
                          <p>3</p>
                            <h5>
                              <a href={val.portfolioLink} target="_blank">
                                {val.title} 
                              </a>
                            </h5>
                            {/* <span>{val.subTitle}</span> */}
                          </div>
                          {/* End .portfolio-info */}
                          <VideoJS options={PhotographyPortfolioContent[i]} onReady={handlePlayerReady} />

                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* End list wrapper */}
              </TabPanel>

            </SRLWrapper>
            {/* End tabpanel */}
          </Tabs>
        </div>
      </div>
    </SimpleReactLightbox>
  );
};

export default Portfolio;
