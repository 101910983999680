import React from 'react'
import Contact from '../components/contact/Contact'
import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import useDocumentTitle from '../components/useDocumentTitle'
export default function Contacts() {
  useDocumentTitle('Contact Us')
  return (
    <div className="home-dark">
    <Header />
      <section id="contact" className="section after-left-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 my-3">
              <div className="contct-form">
                <div className="sm-title">
                  <h4 className="font-alt">Contact us</h4>
                </div>
                {/* End .sm-title */}
                <Contact />
              </div>
            </div>
            {/* End .col */}

            <div className="col-lg-4 ml-auto my-3">
              <div className="contact-info">
                <div className="sm-title">
                  <h4 className="font-alt">Get in touch</h4>
                   
                  {/* <p>
                    Always available for freelancing if the right project comes
                    along, Feel free to contact me.
                  </p> */}
                </div>
                <div className="media">
                  <div className="icon">
                    <i className="ti-map"></i>
                  </div>
                  <span className="media-body">
                    
                    {/* 123 Stree New York City , <br />
                    United States Of America <br />
                    NY 750065. */}
                    Pambavasan Nair<br />
PO Box: 70139<br />
Sitra<br />
Kingdom of Bahrain<br />

                  </span>
                </div>
                {/* End media */}

                <div className="media">
                  <div className="icon">
                    <i className="ti-email"></i>
                  </div>
                  <span className="media-body">
                  pambavasan@amadbaeed.com
                  </span>
                </div>
                {/* End media */}

                <div className="media">
                  <div className="icon">
                    <i className="ti-mobile"></i>
                  </div>
                  <span className="media-body">
                  +973 17456590
                    
                  </span>
                </div>

                <div className="media">
                  <div className="icon">
                    <i className="ti-link"></i>
                  </div>
                  <span className="media-body">
                
                  <ul style={{padding:0,marginBottom:'10px'}}>
                    <li> <a href='https://pambavasannair.com/' target='_blank'>www.pambavasannair.com</a></li>
                    <li><a href='https://amadbaeed.com/' target='_blank'>www.amadbaeed.com</a></li>
                    <li>  <a href='https://asconcontrol.com/' target='_blank'>www.asconcontrol.com</a></li>
                  </ul>
                    
                  </span>
                </div>
                {/* End media */}
              </div>
              
            </div>
            {/* End .col */}
          </div>
        </div>
      </section>
    <footer className="footer white">
          <div className="container">
            <Footer />
          </div>
        </footer>
    </div>
  )
}
