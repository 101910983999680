import React, { useState,useRef } from "react";
import Scrollspy from "react-scrollspy";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

const Header = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(true);

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const onClick = () => setIsActive(!isActive);


  const changeBackground = () => {
    if (window.scrollY >= 71) {
      setNavbar(true);
    } else {
      setNavbar(true);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      {/* Header */}
      <header className={navbar ? "main-header fixed-header" : "main-header"}>
        <nav className="container">
          <div className="header-transparent">
            {/* Brand */}
            <NavLink to="/">
              <img
                className="navbar-brand"
                src="img/logo/logo.png"
                alt="brand logo"
              />
            </NavLink>
            {/* / */}
            {/* Mobile Toggle */}
            <button
              className="navbar-toggler"
              type="button"
              onClick={handleClick}
            >
              <div className={click ? "hamburger active" : "hamburger"}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
            {/* / */}
            {/* Top Menu */}
            <div
              className={
                click
                  ? "mobile-menu navbar-collapse justify-content-end active"
                  : "mobile-menu navbar-collapse justify-content-end"
              }
            >
              <Link to="/" className="sidebar-logo">
                <img src="img/logo/logo-white.png" alt="brand" />
              </Link>
              <Scrollspy
                className="anchor_nav navbar-nav ml-auto"
                items={["home", "about","CMNTrust","services","group-companies","awards","photo-gallery","video-gallery", "articles","contact"]}
                currentClassName="current"
                offset={-71}
              >
                <li><a className="nav-link" href="/" ><span>Home</span></a></li>
                <li><a className="nav-link" href="about-us"><span>Profile</span></a></li>
                <li><a className="nav-link" href="CMNTrust"><span>CMN Trust</span></a></li> 
                <li><a className="nav-link" href="services"><span>Services</span></a></li>
                <li><a className="nav-link" href="group-companies"><span>Group Companies</span></a></li>
                <li><a className="nav-link" href="awards"><span>Awards</span></a></li>

                <li className="dropdown"><a className="btn btn-secondary dropdown-toggle" href="javascript:void(0)"
       //   onClick={onClick}
          onMouseOver={() => setIsActive(true)}
          onMouseLeave={() => setIsActive(false)}
          onFocus={() => setIsActive(true)}
          toggle={() => setIsActive(!isActive)}

          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Gallery
        </a>

        <ul onMouseOver={() => setIsActive(true)}   onMouseLeave={() => setIsActive(false)}
 className={`menu ${isActive ? 'active' : 'inactive'}`}>
          <li>
            <a className="dropdown-item" href="/photo-gallery" >
              Photo Gallery
            </a>
          </li>
          <li>
            <a className="dropdown-item" href="/video-gallery">
              Video Gallery
            </a>
          </li>
           
        </ul>
      </li>
               
      <li>
                  <a className="nav-link" href="articles" onClick={handleClick} id="articles">
                    <span>Articles</span>
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="news" onClick={handleClick}  id="news">
                    <span>News</span>
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="contact-us" onClick={handleClick}>
                    <span>Contact</span>
                  </a>
                </li>
                
              </Scrollspy>
              <button
                className=" sidebar-bottom-closer"
                type="button"
                onClick={handleClick}
              >
                Close Sidebar
              </button>
            </div>
            {/* / */}
          </div>
          {/* Container */}
        </nav>
        {/* Navbar */}
      </header>
      {/* End Header */}
    </>
  );
};

export default Header;
