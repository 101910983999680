import React from 'react'
import About from '../components/about/AboutSix'
import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import useDocumentTitle from '../components/useDocumentTitle'
import { Helmet } from 'react-helmet'

export default function Abouts() {
 
 return (
  <>
    <Helmet>
                
                <title>Nair | Philanthropist GCC-Amad Group Amad Baeed Ascon Control</title>
                <meta name="keywords" content="Philanthropist in GCC,
Amad Group,
Amad Baeed,
Ascon Control,
Industrialist in GCC" />
                <meta name="description" content="As an industrialist, Nair started his journey in 2007. He is immensely popular as a Philanthropist
in GCC. He owns Amad Group, Amad Baeed,&amp; Ascon Control, etc." />


            </Helmet>
  <div className="home-dark">
  <Header />
    <section id="about" className="section about-section after-left-section">
    <div className="container">
      <About/>
    </div>
  </section>
  <section  className="section about-section " style={{padding:'20px'}}>
  <div className="row align-items-center justify-content-center">

<div className="col-lg-12 my-3">
<div className="typo-box about-me">
{/* <p>
He started his career as a trainee maintenance engineer and because of his excellent performance got soon promoted as Shift Engineer. Being quite ambitious he switched on to sales and joined a switchgear manufacturing company. The management realizing his potentials, posted him as Sr. Sales Engineer, then on soon getting promoted as Sales Manager. In the year 1993, he reached Saudi Arabia as Sales Engineer wherein he was entrusted the position as Project & Showroom Sales Manager. In the year 1998 he ventured into business, starting a trading company called Amad Al Baeed Trading Est. in Saudi Arabia. The company within a short span of 2-3 years got well established. Following the success in Saudi Arabia, his business expansion in Bahrain was initiated in the year 2001. Amad Group has today successfully completed 24 years of an incredible journey in the gulf region of Bahrain and Saudi Arabia. Amad presently has 4 companies in Saudi Arabia and as well as 4 companies in Bahrain with 400 employees. With his exemplary entrepreneurial skills, he nurtured Amad into a professional business enterprise.

 </p> */}
 <p>
             After establishing a reputed trading house, to mark his signature he ventured into industrial sector and invested to initiate advanced switchgear factories named Ascon Control WLL in Bahrain (2007) & Ascon Control Co. Ltd. in KSA (2010). During the challenging pandemic times, he invested another BD.1.8 Million to build a full-fledged factory with advanced technologies at Al Mazraa Industrial Park, Bahrain,  which received commendable appreciation from the rulers.  The new building premises was inaugurated Ex- Minister of Industry & Commerce Mr. Zayed R. Alzayani & former Indian Ambassador to the Kingdom to Bahrain Mr.Piyush Srivastava. Creating history, he is recognized as the first expatriate to initiate a fully automated switchgear factory in Bahrain and the first Indian expatriate to initiate an automated Swtichgear factory  in Saudi Arabia as well. 


             </p>
<p>
The ruling family’s His Highness Shaikh Isa bin Salman bin Hamad Al Khalifa visited Mr Pambavasan Nair’s residence on Diwali day to personally extend the Bahraini ruler His Royal Highness Prince Salman bin Hamad Al Khalifa’s Diwali greetings. The rulers visit only few reputed Indian families at their residence & Mr Pambavasan is the first Indian from southern part of India to be honored by the visit of His Highness Shaikh Isa bin Salman bin Hamad Al Khalifa. Many eminent cabinet ministers also visited his residence to convey Diwali greetings & celebrate the occasion. 
</p>

<p>
Mr Pambavasan Nair is immensely popular as a Philanthropist in GCC & in India. His activities are not just restricted to business, he is equally in the forefront when it comes to humanitarian works & social causes. From his childhood he witnessed his parents helping the needy which deeply influenced him to do good charitable works for the benefit of society. Succeeding their footsteps, he has always been involved in charity works. Pandemic times, Sankar hospital which is his 100 bedded hospital in Kerala was given to the state government for two years time period as a quarantine center for taking care of the COVID-19 patients & for quarantining the NRI’s who returned to their hometown. He has constructed many houses for the underprivileged in India. He has rendered financial aid for building homes to many families. He has been all along helping the families of his employees who lost their life by terminal illness, rendering financial aid to them every month. He helped many people with their expenses for medical treatment. Covid days when many people across the globe lost job & received deducted amount of salary, in his company he ensured that there was no termination of job or any salary deduction. He has done enormous charity to help the needy irrespective of caste, creed or religion. Be it aiding educational institutions, sponsoring child education, for marriages, for temple, mosque or church construction, every possible aid in his capacity is rendered by him for social causes. He holds on to the belief, “Each of us must work for his own improvement and at the same time share a general responsibility for all humanity.
</p>




</div>
</div>
</div>
  </section>
  <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
  </div>
  </>
  )
}
