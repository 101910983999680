import React from 'react'
import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import NewsPortfolio from '../components/portfolio/NewsPortfolio.js'
import ImagePortfolio from '../components/portfolio/ImagePortfolio'
import useDocumentTitle from '../components/useDocumentTitle'
export default function News() { 
  useDocumentTitle('News') 
  return (
    <div className="home-dark">
    <Header />
    <section id="awards" className="section gray-bg">
        <div className="container">
          <div className ="row justify-content-center section-title text-center">
            <div className="col-lg-12">
              <h3 className="font-alt">CMN Trust</h3>
              <p>Mr. Pambavsan Nair founded CMN trust in the year 2014 which is named after his late parents, Chandramma Madhavan Nair Trust. Since its inception the trust has been involved in many charitable activities. Later it was formally registered in the year 2023 to provide impetus to various philanthropic initiatives & contributes to a positive societal impact by aiding house construction for the needy, child education, pension to elderly persons, hospital treatment, marriages, employment opportunities as such. It is committed to enhance the quality life of people from vulnerable communities.</p>
              <a className="text-primary" target="_blank" href="https://www.cmntrust.com/"> <b>For more details please visit www.cmntrust.com</b></a>
            <br/><br/>
            <h3  className="font-alt"> A Home of Your Own – 50 Houses in 5 Years</h3> 
            <p>CMN Trust as part of its commitment has in past constructed many houses for the needy. Recently during his new house warming function in Kerala Mr.
               Pambavasan Nair has announced to grant 50 houses to the underprivileged in a course of 5 year time period under CMN Trust. Every year,  10 eligible
                families will be selected, the trust will hand over them a house to call their own.  The official launching ceremony was  held on 25 August 2024
                 at Ottapalam, kerala and Memorandum of Understanding  were presented to 10 families selected for the aid this year. </p>
                 
                 <br/><br/>
                 <iframe width="100%" height="615" src="https://www.youtube.com/embed/Xmb4Oy_d8sg?si=qefuPl8lR-RQEJdS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>      
                 <br/><br/>
                 <p> The event was graced by the esteemed presence of many prominent personalities of Kerala, Chief Guest Shri M.B. Rajesh (Hon. Minister for Local Self Governments 
                 and Dept. of Excise), Special Guest Shri A.K. Saseendran (Hon. Minister for Forests & Wild Life Protection), guest of honors Shri V.K 
                 Sreekandan (Hon. Member of Parliament - Lok Sabha), Shri P.P. Suneer (Hon Member of Parliament -Rajya Sabha), Shri P.K. Krishnadas 
                 (IRPAC Chairman), Shri Lal Jose (Actor, Director & Producer) and Adv. P.T. Narendra Menon (Author, Poet & Literary Scholar).</p>
              <br/>

              <div className="row align-items-center justify-content-center">
                <div className="col-lg-3 my-3">
                  <div className="img-box">
                    <img src="img/cmntrust/001.jpg" className="img-fluid" alt="CMN Trust" />
                  </div>
                </div>
                <div className="col-lg-3 my-3">
                  <div className="img-box">
                    <img src="img/cmntrust/002.jpg" className="img-fluid" alt="CMN Trust" />
                  </div>
                </div>
                <div className="col-lg-3 my-3">
                  <div className="img-box">
                    <img src="img/cmntrust/003.jpg" className="img-fluid" alt="CMN Trust" />
                  </div>
                </div>
                <div className="col-lg-3 my-3">
                  <div className="img-box">
                    <img src="img/cmntrust/004.jpg" className="img-fluid" alt="CMN Trust" />
                  </div>
                </div>
                <div className="col-lg-3 my-3">
                  <div className="img-box">
                    <img src="img/cmntrust/005.jpg" className="img-fluid" alt="CMN Trust" />
                  </div>
                </div>
                <div className="col-lg-3 my-3">
                  <div className="img-box">
                    <img src="img/cmntrust/006.jpg" className="img-fluid" alt="CMN Trust" />
                  </div>
                </div>
                <div className="col-lg-3 my-3">
                  <div className="img-box">
                    <img src="img/cmntrust/007.jpg" className="img-fluid" alt="CMN Trust" />
                  </div>
                </div>
                <div className="col-lg-3 my-3">
                  <div className="img-box">
                    <img src="img/cmntrust/008.jpg" className="img-fluid" alt="CMN Trust" />
                  </div>
                </div>
                <div className="col-lg-3 my-3">
                  <div className="img-box">
                    <img src="img/cmntrust/009.jpg" className="img-fluid" alt="CMN Trust" />
                  </div>
                </div>
                <div className="col-lg-3 my-3">
                  <div className="img-box">
                    <img src="img/cmntrust/010.jpg" className="img-fluid" alt="CMN Trust" />
                  </div>
                </div>
                <div className="col-lg-3 my-3">
                  <div className="img-box">
                    <img src="img/cmntrust/011.jpg" className="img-fluid" alt="CMN Trust" />
                  </div>
                </div>
                <div className="col-lg-3 my-3">
                  <div className="img-box">
                    <img src="img/cmntrust/012.jpg" className="img-fluid" alt="CMN Trust" />
                  </div>
                </div><div className="col-lg-3 my-3">
                  <div className="img-box">
                    <img src="img/cmntrust/013.jpg" className="img-fluid" alt="CMN Trust" />
                  </div>
                </div>
                <div className="col-lg-3 my-3">
                  <div className="img-box">
                    <img src="img/cmntrust/014.jpg" className="img-fluid" alt="CMN Trust" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    <footer className="footer white">
          <div className="container">
            <Footer />
          </div>
        </footer>
    </div>
  )
}
