import React from 'react'
import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import GroupPortfolio from '../components/portfolio/GroupPortfolio'
import ImagePortfolio from '../components/portfolio/ImagePortfolio'
import { Helmet } from 'react-helmet'
import useDocumentTitle from '../components/useDocumentTitle'
export default function GroupCompany() {
 
  return (
    <> 
    
    <Helmet>
                
                <title>Nair | Successful Entrepreneur and Industrialist in GCC</title>
                <meta name="keywords" content="Philanthropist in GCC,
Amad Group,
Amad Baeed,
Ascon Control,
Industrialist in GCC" />
                <meta name="description" content="Mr Nair has been popular as a Successful Entrepreneur and Industrialist in GCC. He has 4
companies in Bahrain &amp; Saudi Arabia with different activities." />


            </Helmet>
       
    <div className="home-dark">
  <Header />
  <section id="group-companies" className="section services-section gray-bg">
        <div className="container">
          <div className="row justify-content-left section-title text-center">
            <div>
            <h3 className="font-alt">Group Companies</h3>
              <p style={{fontStyle:"italic",fontSize:'20px',color:'orange'}}>
              Mr. Pambavasan Nair, Managing Director of Amad Group of Companies
              </p>
              <div style={{textAlign:'justify'}}>
              <p>
              In the year 1998, Mr Nair ventured into business & started a trading company Amad Baeed in Saudi Arabia. Further expanding his horizons he started business in Bahrain in the year 2001. He has 4 companies in Bahrain & he has 3 companies in Saudi Arabia with different activities. Amad has a professionally managed group with a technically qualified, competent & committed sales team handling the various requirements of clients. Amad Group has successfully completed 26 years of incredible journey in the gulf region of Bahrain & Saudi Arabia.
              </p>
              </div>
            </div>

            
          </div>

          <div className='row'>
        
              <GroupPortfolio/>
            

          
            </div>
          {/* End .row */}
          {/* <Service /> */}
        </div>
      </section>
  <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
  </div>
  </>
  )
}
