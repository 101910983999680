import React from "react";
import Header from "../../components/header/Header";
import Slider from "../../components/slider/SliderFiveVideoDark";
import About from "../../components/about/AboutSix";
import Service from "../../components/service/Service";
import Skills from "../../components/skills/Skills";
import Portfolio from "../../components/portfolio/Portfolio";
import Testimonial from "../../components/testimonial/Testimonial";
import Blog from "../../components/blog/Blog";
import Contact from "../../components/contact/Contact";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../components/useDocumentTitle";
import NewAbout from "../../components/about/NewAbout";
import {Helmet} from "react-helmet";

const HomeSix = () => {
  useDocumentTitle("Home");
  return (
    <>
          <Helmet>
                
                <title>Pambavasan Nair | Business Man in Saudi Bahrain GCC</title>
                <meta name="keywords" content="Pambavasan Nair,
Bahrain Businessman,
Businessman in GCC,
Saudi Businessman" />
                <meta name="description" content="Pambavasan Nair; a well-known businessman in Saudi Arabia and Bahrain ventured into
business in 1998 and is well established now in GCC" />


            </Helmet>
    <div className="home-dark" id="home">
   
      <Header />
      {/* End Header Section */}
      <Slider />
      {/* End Banner Section */}

      {/* About Me */}
      <section   className="section about-section after-left-section">
        <div className="container">
          <NewAbout />
        </div>
      </section>
      {/* End About Me */}




      {/* Services */}
     
      {/* End Services */}

      {/*  Skills */}
      {/* <section className="section skill-section">
        <div className="container">
          <Skills />
        </div>
      </section> */}
      {/* End Skills */}

     
      {/* End Portfolio */}

      {/* <div className="section testimonial">
        <div className="container">
          <Testimonial />
        </div>
      </div> */}
      {/* End testimonial */}

            {/*  Skills */}
      
      {/* End Skills */}


      {/* Blog */}
      {/* <section id="blog" className="section gray-bg">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Recent articles</h3>
              <p>
                I design and develop services for customers of all sizes,
                specializing in creating stylish, modern websites, web services
                and online stores.
              </p>
            </div>
          </div>
        
          <Blog />
        </div>
      </section> */}
      {/*  End Blog */}

      {/* Contact */}
     
      {/* End Contact */}

      {/* Footer */}
      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
      {/*  End Footer */}
    </div>
    </>
  );
};

export default HomeSix;
